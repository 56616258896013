<template>
  <b-card
    id="card-loading"
    class="p-2"
    title="Interview Process"
  >
    <h4
      class="my-6 mx-auto"
      style="color: red"
    >
      Do not close this tab or refresh this page while the interview is in
      progress.
    </h4>
    <p>Please proceed with all steps to complete the whole interview.</p>
    <!-- @on-complete="formSubmitted" -->
    <div class="mt-2">
      <b-overlay
        :show="isLoading"
        rounded="sm"
      >
        <form-wizard
          id="form-with-loading"
          ref="wizard"
          color="#f47920"
          class="mb-3"
          :title="null"
          :subtitle="null"
          finish-button-text="Submit"
        >
          <tab-content
            :title="admin ? 'Current candidate' : 'Current employer'"
            class="mb-1"
            :before-change="selectApplicants"
          >
            <b-modal
              v-model="popupList"
              centered
              hide-footer
              title="Incomplete Profile"
            >
              <b-row class="mt-2 mx-auto">
                <h6>
                  Please complete your profile before the interview starts.
                </h6>
              </b-row>
              <b-row class="mt-2 mx-auto">
                <b-button
                  to="/profile"
                  variant="warning"
                  type="filled"
                >Finish Profile</b-button>
              </b-row>
            </b-modal>
            <hr>
            <div class="d-flex justify-content-between">
              <div
                v-if="!video_call"
                class="w-100"
              >
                <b-row
                  v-if="admin"
                  cols="12"
                  class=""
                >
                  <div
                    class="d-flex flex-column justify-content-start w-100 mx-2"
                  >
                    <h6 class="my-1 w-100 font-weight-bolder">
                      Select an event to start an interview
                    </h6>
                    <v-select
                      v-model="selected_event"
                      placeholder="Select an event to start an interview"
                      :options="all_events"
                      label="text"
                      class="w-100"
                      :reduce="text => text.value"
                    />
                  </div>

                  <b-button
                    v-if="
                      !$isEmpty(applicant_details) && show_section
                    "
                    variant="success"
                    class="my-1 ml-2"
                    @click="requestVideoCall"
                  >
                    Start Interview
                  </b-button>
                </b-row>
                <b-row v-else>
                  <b-button
                    variant="success"
                    class="ml-2"
                    @click="acceptVideoCall"
                  >
                    Accept Interview
                  </b-button>
                </b-row>
              </div>
              <div v-else>
                <div v-if="admin == true">
                  <b-button
                    v-if="Object.keys(applicant_details).length > 0"
                    variant="danger"
                    class="mx-2"
                    @click="endInterview"
                  >
                    End Interview
                  </b-button>
                </div>
              </div>

              <div
                v-if="
                  (selected_job &&
                    selected_job.event_type === 'speed_interview') ||
                    (event_details &&
                      event_details.event_type === 'speed_interview')
                "
                class="flex"
              >
                <countdown
                  ref="countdown"
                  :auto-start="auto_start"
                  :time="1 * 1 * minutes * 60 * 1000"
                >
                  <template slot-scope="props">
                    <h5
                      :style="
                        props.minutes < 3
                          ? 'color:red;'
                          : 'color: rgb(40, 199, 111);'
                      "
                    >
                      Time remaining:
                      {{ props.minutes.toString().length == 1 ? '0' : ''
                      }}{{ props.minutes }}:{{
                        props.seconds.toString().length == 1 ? '0' : ''
                      }}{{ props.seconds }}
                    </h5>
                  </template>
                </countdown>
              </div>
            </div>

            <hr>
            <div class="d-flex flex-wrap">
              <b-col
                v-if="video_call == false"
                cols="12"
                md="8"
                class=""
              >
                <b-card
                  border-variant="dark"
                  bg-variant="transparent"
                  class="shadow-none p-2 w-100"
                >
                  <div>
                    <img
                      :src="require('@/assets/images/play.png')"
                      alt="user-profile-cover"
                      style="height: 60vh"
                      class="blur-1 interview-overlay"
                    >
                    <div class="card-overlay text-white">
                      <div class="d-flex flex-column justify-between">
                        <div class="text-center mt-4 w-100">
                          <p
                            v-if="admin == true"
                            class="text-white tracking-wide"
                          >
                            When you are ready to begin the interview with the
                            current <br>candidate, please press the button
                            above or skip to get the next candidate
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-card>
              </b-col>
              <b-col
                v-else
                id="conference"
                cols="12"
                md="8"
                style="height: 65vh"
              >
                <iframe
                  v-if="meetUrl && meetUrl.length > 2"
                  allow="geolocation *; microphone *; camera *; display-capture *;"
                  allowFullScreen="true"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  sandbox="allow-same-origin allow-scripts allow-modals allow-forms"
                  style="width: 100%; height: 100%; border: 0"
                  scrolling="no"
                  :src="meetUrl"
                  @load="iframeLoadHelper"
                />
              </b-col>
              <b-col
                v-if="admin == true"
                cols="12"
                md="4"
                class=""
              >
                <b-card style="overflow-y: scroll; height: 65vh">
                  <b-row
                    v-if="
                      $isEmpty(applicant_details.details)
                    "
                    class="my-auto"
                  >
                    <h6 class="my-auto">
                      {{ applicant_details.details }}
                      There are no more pending candidates to interview.
                    </h6>
                  </b-row>
                  <div v-else>
                    <div
                      v-if="
                        !$isEmpty(applicant_details.details)
                      "
                    >
                      <b-row class="vx-row my-1">
                        <h5 class="mx-2 font-weight-bolder">
                          Position : {{ selected_job.position }}
                        </h5>
                      </b-row>

                      <hr>

                      <b-row class="">
                        <h5 class="mx-2 font-weight-bolder">
                          Applicant Details
                        </h5>
                      </b-row>

                      <hr>

                      <b-row class="">
                        <div class="d-flex justify-content-between">
                          <b-avatar
                            class="mx-1 mb-1 block"
                            size="40px"
                            :src="
                              $isEmpty(applicant_details.details.profile_image_url)
                                ? require('@/assets/images/placeholder/no-image.jpg')
                                : applicant_details.details.profile_image_url
                            "
                          />
                          <span class="ml-2">
                            {{ applicant_details.details.fullname }}
                          </span>
                          <span
                            v-if="
                              online_users.includes(
                                applicant_details.details.email_address,
                              )
                            "
                            class="text-success"
                          >
                            (Online)
                          </span>
                          <span
                            v-else
                            class="text-danger"
                          > (Offline) </span>
                        </div>
                      </b-row>

                      <b-row class="mt-1">
                        <h5 class="mx-2 font-weight-bolder">
                          Education Details
                        </h5>
                      </b-row>

                      <hr>

                      <div
                        v-if="!$isEmpty(applicant_details.details.educations)"
                        class=""
                      >
                        <div
                          v-for="(info, index) in applicant_details.details.educations"
                          :key="index"
                        >
                          <div class="mt-1">
                            <div class="d-flex justify-content-between">
                              <b-col
                                v-if="info.school_img"
                                cols="2"
                              >
                                <b-avatar
                                  class="mx-2 mb-6 block"
                                  size="60px"
                                  :src="
                                    info.school_img == 'default'
                                      ? require('@/assets/images/placeholder/no-image.jpg')
                                      : info.school_img
                                  "
                                />
                              </b-col>
                              <b-col
                                cols="10"
                                class="ml-2 w-75"
                                :class="info.school_img ? 'w-75' : 'w-100'"
                              >
                                <b-row>
                                  <h6 class="underline">
                                    <u class="font-weight-bolder">{{ info.school_name }}</u>
                                  </h6>
                                </b-row>
                                <b-row>
                                  <span>{{ info.field_of_study }}</span>
                                </b-row>
                                <b-row>
                                  <span>{{ info.start_date }} -
                                    {{ info.end_date }}</span>
                                </b-row>
                              </b-col>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-else
                        class="mb-3"
                      >
                        <span>No education details available for this
                          applicant.</span>
                      </div>

                      <b-row class="mt-1">
                        <h5 class="mx-1 font-weight-bolder">
                          Employment Details
                        </h5>
                      </b-row>

                      <hr>

                      <div
                        v-if="!$isEmpty(applicant_details.details.employments)"
                        class=""
                      >
                        <div
                          v-for="(info, index) in applicant_details.details.employments"
                          :key="index"
                        >
                          <div class="mt-1">
                            <div class="d-flex justify-content-between">
                              <b-col
                                v-if="info.company_img"
                                cols="2"
                                class="vx-col md:w-1/4"
                              >
                                <b-avatar
                                  class="mx-2 mb-6 block"
                                  size="60px"
                                  :src="
                                    info.company_img == 'default'
                                      ? require('@/assets/images/placeholder/no-image.jpg')
                                      : info.company_img
                                  "
                                />
                              </b-col>
                              <b-col
                                cols="10"
                                class="ml-2 w-75"
                                :class="info.company_img ? 'w-75' : 'w-100'"
                              >
                                <b-row>
                                  <h6 class="underline">
                                    <u class="font-weight-bolder">{{ info.position }}</u>
                                  </h6>
                                </b-row>
                                <b-row>
                                  <span> {{ info.company_name }}</span>
                                </b-row>
                                <b-row>
                                  <span>{{ info.start_date }} -
                                    {{ info.end_date }}</span>
                                </b-row>
                              </b-col>
                            </div>
                            <b-row class="ml-2">
                              {{ info.description }}
                            </b-row>
                          </div>
                        </div>
                      </div>
                      <div
                        v-else
                        class="mb-2"
                      >
                        <span>No employment details available for this
                          applicant.</span>
                      </div>

                      <b-row
                        v-if="
                          !$isEmpty(Object.keys(applicant_details.answers || {}))
                        "
                        class="vx-row mt-1"
                      >
                        <h5 class="mx-1 font-weight-bolder">
                          Screening Questions
                        </h5>
                      </b-row>
                      <hr
                        v-if="
                          !$isEmpty(Object.keys(applicant_details.answers || {}))
                        "
                      >
                      <b-row
                        v-if="
                          !$isEmpty(Object.keys(applicant_details.answers || {}))
                        "
                        class="ml-2"
                      >
                        <p
                          v-for="(
                            value, index, propertyName
                          ) in applicant_details.answers"
                          :key="index"
                          class="w-100"
                        >
                          {{ index + 1 }}. {{ value.question }}: <b>{{ value.answer }}</b>
                        </p>
                      </b-row>
                    </div>
                  </div>
                </b-card>
              </b-col>

              <b-col
                v-else
                cols="12"
                md="4"
                class=""
              >
                <b-card style="overflow-y: scroll; height: 70vh">
                  <b-row
                    v-if="
                      $isEmpty(event_details)
                    "
                    class="vx-row"
                  >
                    <h5>You are done with all interviews.</h5>
                  </b-row>
                  <div v-else>
                    <div
                      v-if="
                        !$isEmpty(event_details)
                      "
                    >
                      <b-row>
                        <h5 class="mx-1">
                          Employer Details
                        </h5>
                      </b-row>
                      <hr>
                      <b-row class="mx-1 mt-1">
                        <b-col
                          cols="12"
                          md="3"
                        >
                          <b-avatar
                            class="block"
                            size="60px"
                            :src="
                              event_details.company_image == 'default'
                                ? require('@/assets/images/placeholder/no-image.jpg')
                                : event_details.company_image
                            "
                          />
                        </b-col>
                        <b-col
                          cols="12"
                          md="8"
                          class="mt-1 ml-1"
                        >
                          <h5>{{ event_details.company }}</h5>
                        </b-col>
                      </b-row>
                      <hr>
                      <b-row class="mb-1">
                        <div>
                          <b-col class="ml-1">
                            <h5>Position : {{ event_details.position }}</h5>
                          </b-col>
                        </div>
                      </b-row>
                      <hr>
                      <b-row class="mx-1 mb-1">
                        <div class="d-flex justify-content-between">
                          <h5>Salary (S$):</h5>
                          <p class="">
                            {{ event_details.min_salary }} -
                            {{ event_details.salary }}
                          </p>
                        </div>
                      </b-row>

                      <hr>
                      <b-row class="mx-1 mb-1">
                        <div class="d-flex justify-content-between">
                          <h5 class="">
                            Location :
                          </h5>
                          <p
                            v-if="event_details.work_location"
                            class="ml-2"
                          >
                            {{ event_details.work_location.address }},
                            {{ event_details.work_location.country }}
                          </p>
                        </div>
                      </b-row>
                      <hr>
                      <b-row class="mt-1 mx-1">
                        <h5 class="">
                          Description of ideal candidate
                        </h5>
                      </b-row>
                      <hr>
                      <b-row class="">
                        <p class="">
                          {{ event_details.type_candidate }}
                        </p>
                      </b-row>

                      <b-row class="mt-1 mx-1">
                        <h5 class="">
                          Job Description
                        </h5>
                      </b-row>
                      <hr>
                      <b-row class="mx-1">
                        <p class="">
                          {{ event_details.description }}
                        </p>
                      </b-row>
                      <hr>
                      <div class="mt-1 mx-1">
                        <h5 class="">
                          Job Requirements
                        </h5>
                      </div>
                      <hr>
                      <div class="mx-1">
                        <p class="">
                          {{ event_details.requirements }}
                        </p>
                      </div>
                    </div>
                  </div>
                </b-card>
              </b-col>
            </div>

            <b-row
              v-if="admin == true"
              cols="12"
              class="mx-2 my-1"
            >
              <b-form-textarea
                v-model="feedback"
                placeholder="Candidate Feedback"
                rows="6"
                class="w-full"
              />
            </b-row>

            <div
              v-if="admin == true && video_call == false"
              class="d-flex flex-row ml-1"
            >
              <div class="mt-1 mr-1">
                <h6 class="">
                  Online Applicants:
                </h6>
              </div>
              <div
                class="d-flex flex-column"
              >
                <b-avatar-group>
                  <div
                    v-for="(details, userIndex) in all_applicants"
                    :key="userIndex"
                  >
                    <div
                      @click="changeCurrentInterviewee(details, userIndex)"
                    >
                        <!-- v-if="details.profile_image !== 'default'" -->
                      <b-avatar
                        v-b-tooltip.hover.top="
                          `${details.fullname} is currently ${
                            online_users.includes(details.email_address)
                              ? 'online'
                              : 'offline'
                          }. Click here to change the current user you want to interview to ${
                            details.fullname
                          }.`
                        "
                        :title="details.fullname"
                        :variant="
                          online_users.includes(details.email_address)
                            ? 'success'
                            : 'danger'
                        "
                        :src="
                          $isEmpty(details.profile_image_url)
                            ? require('@/assets/images/placeholder/no-image.jpg')
                            : details.profile_image_url
                        "
                        class="border-2 border-white border-solid"
                        style="width: 48px; height: 48px;"
                      />

                      <!-- <b-avatar
                        v-else
                        v-b-tooltip.hover.top="
                          `${details.fullname} is currently ${
                            online_users.includes(details.email)
                              ? 'online'
                              : 'offline'
                          }. Click here to change the current user you want to interview to ${
                            details.fullname
                          }.`
                        "
                        :variant="
                          online_users.includes(details.email)
                            ? 'success'
                            : 'danger'
                        "
                        :title="details.fullname"
                        size="lg"
                        class="border-2 border-white border-solid primary"
                        :text="details.email"
                        @click="changeCurrentInterviewee(details, userIndex)"
                      /> -->
                    </div>
                  </div>
                </b-avatar-group>
              </div>
            </div>
          </tab-content>

          <!-- tab 3 content -->
          <tab-content
            :title="
              admin
                ? 'Please rate the current candidate before proceeding to the next'
                : 'Rate your experience with the employer'
            "
            class="mb-1"
          >
            <b-row
              v-if="admin"
              class="mt-1 mb-1"
            >
              <h4
                v-if="applicant_details.details"
                class="ml-1"
              >
                What do you think of {{ applicant_details.details.fullname }}?
              </h4>
            </b-row>

            <div
              v-else
              class="d-flex mt-1 mb-1 mx-auto"
            >
              <b-col class="mx-auto">
                <h4 class="mx-auto">
                  How was your interview experience?
                </h4>
                <star-rating
                  v-model="applicant_rating"
                  class="mx-auto"
                  :increment="0.5"
                  :star-size="30"
                />
              </b-col>
            </div>

            <b-row class="vx-row">
              <b-col
                v-if="admin"
                cols="12"
                md="3"
              >
                <h6 class="mt-1">
                  Attitude
                </h6>
                <star-rating
                  v-model="attitude"
                  :increment="0.5"
                  :star-size="30"
                />
                <h6 class="mt-1">
                  Communication
                </h6>
                <star-rating
                  v-model="communication"
                  :increment="0.5"
                  :star-size="30"
                />
                <h6 class="mt-1">
                  Cultural Fit
                </h6>
                <star-rating
                  v-model="culture"
                  :increment="0.5"
                  :star-size="30"
                />
                <h6 class="mt-1">
                  Experience
                </h6>
                <star-rating
                  v-model="experience"
                  :increment="0.5"
                  :star-size="30"
                />
                <h6 class="mt-1">
                  Skills and Knowledge
                </h6>
                <star-rating
                  v-model="skills"
                  :increment="0.5"
                  :star-size="30"
                />
              </b-col>

              <b-col
                cols="12"
                md="9"
              >
                <b-row
                  v-if="admin"
                  class="vx-row mx-1 mt-2"
                >
                  <v-select
                    v-model="selected_application_status"
                    placeholder="Choose the application status"
                    class="w-100"
                    :options="application_statuses"
                    label="value"
                    :reduce="value => value.value"
                  />
                </b-row>
                <b-row class="vx-row mx-1 mt-2">
                  <b-form-textarea
                    v-model="feedback"
                    :placeholder="
                      admin ? 'Candidate Feedback' : 'Your feedback'
                    "
                    rows="8"
                    class="w-full"
                  />
                </b-row>

                <b-button
                  class="mx-1 mt-2"
                  variant="success"
                  @click="afterFeedback"
                >
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </tab-content>
          <template
            slot="footer"
            slot-scope="props"
          >
            <div class="wizard-footer-left" />
            <div class="wizard-footer-right" />
          </template>
        </form-wizard>
      </b-overlay>
    </div>
    <ProfileCompletionForm
      :activate-popup="popupProfileQuestions"
      :hide-header="true"
      @open-completion-form="activateTypeForm"
    />
  </b-card>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'

import { completionMixins } from '@/components/mixins/CompletionMixins'

import StarRating from 'vue-star-rating'
import BigBlueButtonApi from 'bigbluebutton-js'
import VSelect from 'vue-select'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import useJwt from '@/auth/jwt/useJwt'
import {
  BCard,
  BFormTextarea,
  BFormInput,
  BButton,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BModal,
  VBModal,
  VBTooltip,
  BBadge,
  BAvatar,
  BOverlay,
  BFormCheckbox,
  BFormInvalidFeedback,
  BAvatarGroup,
} from 'bootstrap-vue'

export default {
  components: {
    VSelect,
    BCard,
    BFormTextarea,
    BFormInput,
    BAvatarGroup,
    BButton,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BModal,
    VBModal,
    BBadge,
    BAvatar,
    BOverlay,
    BFormCheckbox,
    BFormInvalidFeedback,
    FormWizard,
    TabContent,
    StarRating,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  mixins: [completionMixins],
  data() {
    return {
      interview_in_progress: [],
      event_id: '',
      all_events: [],
      selected_event: '',
      event_date: 'XXXX-XX-XX',
      popupList: false,
      incomplete: false,
      all_event_ids: [],
      attitude: 0,
      communication: 0,
      culture: 0,
      experience: 0,
      skills: 0,
      event_details: {},
      feedback: '',
      approved: 'pending',
      online_users: [],
      video_call: false,
      all_applicants: [],
      selected_job: [],
      applicant_details: {},
      already_checked: [],
      skipped: [],
      admin: false,
      show_section: false,
      times_up: false,
      roomname: '',
      email_address: '',
      fullname: '',
      time: '',
      wordString: {},
      start: '',
      end: '',
      interval: '',
      minutes: '08',
      seconds: '00',
      isRunning: false,
      auto_start: false,
      message: '',
      statusType: '',
      statusText: '',
      timer_checker: '',
      timer_data: { applicant: false, employer: false },
      application_statuses: [
        { text: 'Hired', value: 'Hired' },
        { text: 'Shortlisted', value: 'Shortlisted' },
        { text: 'Kept In View', value: 'Kept In View' },
        { text: 'Rejected', value: 'Rejected' },
      ],
      selected_application_status: '',
      bbbUrl: 'https://conference.headhuntershq.com/bigbluebutton',
      bbbSecret: 'olxExVRsAj77w1sNU3B1GlIyeJUKlXFVr07dnWUyj4k',
      bbbAPI: null,
      meetUrl: '',
      meetEndUrl: '',
      isLoading: true,
      applicant_rating: null,
    }
  },
  computed: {
    getToolTipText(details) {
      const current_status = this.online_users.includes(details.email)
        ? 'online'
        : 'offline'
      return `${details.fullname} is currently ${current_status}. Click here to change the current user you want to interview to .`
    },
    extractInitials(email) {
      return email.splice(0, 3).toUpperCase()
    },

    jitsiOptions() {
      return {
        roomName: this.roomname,
        noSSL: false,
        userInfo: {
          email_address: this.email_address,
          displayName: this.fullname,
        },
        configOverwrite: {
          disableDeepLinking: true,
          enableNoisyMicDetection: false,
          enableWelcomePage: false,
          noticeMessage: '',
          prejoinPageEnabled: false,
          subject: this.roomname,
        },
        interfaceConfigOverwrite: {
          APP_NAME: 'e2i Jobs Interview Event',
          SHOW_JITSI_WATERMARK: false,
          SHOW_WATERMARK_FOR_GUESTS: false,
          SHOW_CHROME_EXTENSION_BANNER: false,
          DISPLAY_WELCOME_PAGE_CONTENT: false,
          MOBILE_APP_PROMO: false,
          TOOLBAR_BUTTONS: [
            'microphone',
            'camera',
            'closedcaptions',
            'desktop',
            'fullscreen',
            'fodeviceselection',

            'profile',
            'chat',
            'recording',
            'videoquality',
            'filmstrip',
            'tileview',
            'videobackgroundblur',
            'download',
            'mute-everyone',
          ],

          DEFAULT_BACKGROUND: '#474747',
          DEFAULT_LOCAL_DISPLAY_NAME: 'e2i Jobs',
          DEFAULT_LOGO_URL:
            'https://i.ibb.co/4wyDnbs/Head-Hunters-HQ-Icon-Transparent-Background.png',
          DEFAULT_REMOTE_DISPLAY_NAME: '',
          DEFAULT_WELCOME_PAGE_LOGO_URL:
            'https://i.ibb.co/4wyDnbs/Head-Hunters-HQ-Icon-Transparent-Background.png',
        },
        onload: this.onIFrameLoad,
      }
    },
  },
  watch: {
    selected_event(val) {
      // this.getMatch();
      if (this.admin) {
        this.isLoading = true
        localStorage.setItem('selected_event', val)
        this.getApplicants()
      }
    },
  },
  mounted() {
    const incompleteProfile = this.checkProfileCompletion()

    if (incompleteProfile) {
      this.popupProfileQuestions = true
    }
    this.$socket.client.on('all_online_users', data => {
      this.online_users = data.data
      if (this.online_users === undefined) {
        this.online_users = []
      }
    })

    this.$socket.client.on('interviewer_link', data => {
      if (this.admin) {
        this.meetUrl = data.data.interviewer_link
        this.meetEndUrl = data.data.meeting_end_link

        this.$store.commit(
          'auth/UPDATE_MEETING_END_LINK',
          data.data.meeting_end_link,
        )
        this.$store.commit(
          'auth/UPDATE_INTERVIEWER_LINK',
          data.data.interviewer_link,
        )
        this.isLoading = false
      }
    })

    this.$socket.client.on('interview_in_progress', data => {
      this.interview_in_progress = data.data
    })

    this.$socket.client.on('received_video_call_requests', data => {
      if (data) {
        this.receivedVideoCall(data)
      }
    })

    this.$socket.client.on('end_interview_applicant', data => {
      const current_email = this.$store.state.auth.ActiveUser.email_address
      const { applicant_details } = data
      if (current_email === applicant_details.email_address) {
        localStorage.removeItem('interview_running')
        // this.event_details = []
        // this.$refs.wizard.reset()
        this.$refs.wizard.nextTab()
      }
    })

    this.$socket.client.on('start_interview_employer', data => {
      this.sendAcceptanceRequest(data)
    })

    this.$socket.client.on(`session_check_response-${localStorage.getItem('tracking_id')}`, response => {
      if (response.output.status === 'session_ended') {
        this.$http
          .post(`/api/auth/logout`, {
            access_token: localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName),
            tracking_id: localStorage.getItem('tracking_id'),
            logout_other_sessions: false,
          })
          .then(response => {
            this.$store.commit('auth/UPDATE_USER_INFO', null, { root: true })
            localStorage.removeItem('userInfo')
            localStorage.removeItem('email')
            localStorage.removeItem('tracking_id')
            localStorage.setItem('tracking_id', this.$generateUUID4())
            localStorage.removeItem('accessToken')
            localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
            delete this.$http.defaults.headers.common['Authorization'];
            this.$toastWarning('Session Ended')

            setTimeout(() => {
              if (!this.$router.currentRoute.meta.allowAnonymous) {
                this.$router.push({ name: 'auth-login' })
              }
            }, 100);

          })
          .catch(error => {
            this.$handleErorResponse(error)
            this.isLoading = false
          })

      }
    })

    const auth = this.$store.state.auth.ActiveUser.user_group

    this.admin = auth.includes('admin')
    const selected_event = localStorage.getItem('selected_event')

    if (selected_event) {
      this.selected_event = selected_event
    }
    const interview_details = JSON.parse(
      localStorage.getItem('interview_running'),
    )

    const { incoming_interview } = this.$store.state

    if (incoming_interview) {
      if (!this.admin && !interview_details) {
        this.receivedVideoCall(incoming_interview)
      }
    }
    this.$nextTick(function () {
      if (interview_details) {
        const { email_address, fullname } = this.$store.state.auth.ActiveUser
        this.email_address = email_address

        this.fullname = fullname
        this.event_details = interview_details.event_details
        this.applicant_details = interview_details.applicant_details
        this.roomname = interview_details.roomname
        this.video_call = true

        this.$toast(
          {
            component: this.$toastContent,
            props: {
              title: 'Interview notification',
              icon: 'AlertCircleIcon',
              text: 'Reconnecting you to the previous interview session.....',
              variant: 'success',
            },
          },
          { timeout: this.$longestTimeout },
        )
        if (this.admin) {
          this.meetUrl = this.$store.state.interviewer_link ? this.$store.state.interviewer_link : localStorage.getItem('interviewer_link')
        } else {
          this.meetUrl = this.$store.state.applicant_link ? this.$store.state.applicant_link : localStorage.getItem('applicant_link')
        }
        if (!this.$isEmpty(this.meetUrl)) {
          this.isLoading = false
        }
      } else if (this.admin) {
        this.getAllEvents()
      } else {
        if (!incoming_interview) {
          this.isLoading = true
        }

        this.$toast(
          {
            component: this.$toastContent,
            props: {
              title: 'Interview notification',
              icon: 'AlertCircleIcon',
              text: 'Waiting for an employer to request an interview with you.',
              variant: 'success',
            },
          },
          { timeout: this.$longestTimeout },
        )
      }

      if (localStorage.getItem('countdown_minutes')) {
        this.minutes = localStorage.getItem('countdown_minutes')
        this.seconds = localStorage.getItem('countdown_seconds')
        if (this.seconds) {
          this.auto_start = false
        } else {
          this.auto_start = true
        }
      }
    })

    const { booking_id } = this.$route.params
    if (booking_id) {
      localStorage.removeItem('interview_running')
      this.$http
        .get(`/api/booking/${booking_id}`)
        .then(response => {
          const { output } = response.data
          const { success } = response.data

          if (success) {
            const { company_name } = this.$store.state.auth.ActiveUser
            this.applicant_details = {}
            this.applicant_details.booking_id = booking_id
            this.times_up = true
            this.$refs.wizard.changeTab(0, 1)
          } else {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Invalid Booking',
                  icon: 'AlertCircleIcon',
                  text: 'Could not get the applicants details.',
                  variant: 'danger',
                },
              },
              { timeout: this.$longestTimeout },
            )
            this.$router.push('/events-manager/rooms')
          }
        })
        .catch(error => {})
    }
  },
  created() {
    this.online_users = this.$store.state.auth.ActiveUser.online_users
    if (this.online_users === undefined) {
      this.online_users = []
    }
    this.bbbAPI = BigBlueButtonApi.api(this.bbbUrl, this.bbbSecret)
  },
  methods: {
    createMeetUrl() {
      const api = BigBlueButtonApi.api(this.bbbUrl, this.bbbSecret)
      const { http } = BigBlueButtonApi

      const moderatorPW = 'md-pass'
      const attendeePW = 'a-pass'

      // api module itself is responsible for constructing URLs
      const meetingCreateUrl = api.administration.create(
        this.roomname,
        this.roomname,
        {
          recordID: this.roomname,
          moderatorPW,
          attendeePW,
          welcome: 'Welcome!',
          fullName: 'Interview Session',
          meetingID: this.roomname,
          redirect: true,
          record: true,
          autoStartRecording: true,
        },
      )

      // http method should be used in order to make calls
      http(meetingCreateUrl)
        .then(result => {
          const moderatorUrl = api.administration.join(
            'interviewer',
            this.roomname,
            moderatorPW,
          )
          const attendeeUrl = api.administration.join(
            'applicant',
            this.roomname,
            attendeePW,
          )
          this.meetUrl = attendeeUrl
          // console.log(
          //   `Moderator link: ${moderatorUrl}\nAttendee link: ${attendeeUrl}`
          // );
          const meetingEndUrl = api.administration.end(
            this.roomName,
            moderatorPW,
          )
          this.$store.commit('auth/UPDATE_APPLICANT_LINK', attendeeUrl)
          this.$socket.client.emit('interviewer_link', {
            interviewer_link: moderatorUrl,
            meeting_end_link: meetingEndUrl,
          })
        })
        .catch(error => {})
    },
    iframeLoadHelper() {
      if (this.iframeReady) {
        this.isLoading = false
      }
    },
    receivedVideoCall(data) {
      const current_email = this.$store.state.auth.ActiveUser.email
      const { applicant_details } = data
      const { event_details } = data

      if (current_email === applicant_details.email) {
        this.minutes = event_details.interview_duration
        this.event_details = event_details
        this.applicant_details = applicant_details

        this.$store.dispatch('auth/updateIncomingInterview', {})

        this.isLoading = false
        this.isLoading = false
      }
    },
    sendAcceptanceRequest(data) {
      const { applicant_details } = data
      const { event_details } = data
      const current_email = this.$store.state.auth.ActiveUser.email
      this.roomname = applicant_details.booking_id

      if (current_email === event_details.interviewer) {
        this.$toast(
          {
            component: this.$toastContent,
            props: {
              title: 'Interview notification',
              icon: 'AlertCircleIcon',
              text: 'Please wait the applicant will join the interview in a few seconds.',
              variant: 'success',
            },
          },
          { timeout: this.$longestTimeout },
        )
        localStorage.setItem(
          'interview_running',
          JSON.stringify({
            applicant_details,
            event_details,
            roomname: this.roomname,
          }),
        )
        this.video_call = true
        this.isLoading = false
        // this.setTime(8, 0);
        // this.$refs.countdown.start();
        this.isLoading = true
      }
    },
    handleCountdownProgress(data) {
      localStorage.setItem('countdown_minutes', data.minutes)
      localStorage.setItem('countdown_seconds', data.seconds)
    },
    changeCurrentInterviewee(details, index) {
      if (details.email_address === this.applicant_details.email_address) {
        this.$toast(
          {
            component: this.$toastContent,
            props: {
              title: 'Interview Section',
              icon: 'AlertCircleIcon',
              text: `You are current viewing ${details.fullname}. To change the current user, click on the other applicants below the video call screen.`,
              variant: 'warning',
            },
          },
          { timeout: this.$longestTimeout },
        )
      } else {
        this.applicant_details = this.all_applicants[index]
        this.$toast(
          {
            component: this.$toastContent,
            props: {
              title: 'Interview Section',
              icon: 'AlertCircleIcon',
              text: `You are now viewing ${details.fullname}.`,
              variant: 'warning',
            },
          },
          { timeout: this.$longestTimeout },
        )
      }
    },

    getAllEvents() {
      this.$http
        .get('/api/get_company_events')
        .then(response => {
          this.all_events = response.data.output
          this.isLoading = false
        })
        .catch(error => {})
    },
    afterFeedback(payload) {
      let all_data = {}
      const { id } = this.$store.state.auth.ActiveUser || {}
      if (this.admin) {
        const feedback_data = {
          attitude: this.attitude,
          communication: this.communication,
          culture: this.culture,
          experience: this.experience,
          skills: this.skills,
        }

        all_data = {
          booking_id: this.applicant_details.booking_id,
          interviewer_id: id,
          application_status: this.selected_application_status,
          note: this.feedback,
          employer_feedback: this.feedback,
          feedback_data,
          company: this.$store.state.auth.ActiveUser.company_name,
        }
      } else {
        all_data = {
          booking_id: this.applicant_details.booking_id,
          applicant_id: id,
          applicant_feedback: this.feedback,
          applicant_rating: {applicant_rating: this.applicant_rating},
        }
      }

      if (this.feedback) {
        this.$http
          .put('/api/book', { data: all_data })
          .then(response => {
            if (response.data.success) {
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: 'Feedback Recorded',
                    icon: 'AlertCircleIcon',
                    text: 'You may now proceed with your next interview.',
                    variant: 'primary',
                  },
                },
                { timeout: this.$longestTimeout },
              ) // this.endInterview();
              // if (this.$router.currentRoute.path !== "/notifications")

              // this.$refs.wizard.reset()
              window.location.reload()
            } else {
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: 'Feedback Recording Failed',
                    icon: 'AlertCircleIcon',
                    text: 'We could not update the candidates feedback.',
                    variant: 'danger',
                  },
                },
                { timeout: this.$longestTimeout },
              )
              this.$refs.wizard.reset()
              window.location.reload()
            }
          })
          .catch(error => {})
      } else {
        this.$toast(
          {
            component: this.$toastContent,
            props: {
              title: 'Feedback Section',
              icon: 'AlertCircleIcon',
              text: 'Please fill all fields in the form and star ratings before proceeding.',
              variant: 'danger',
            },
          },
          { timeout: this.$longestTimeout },
        )
      }
    },
    acceptVideoCall() {
      const { email_address } = this.$store.state.auth.ActiveUser || {}
      const { fullname } = this.$store.state.auth.ActiveUser || {}
      this.email_address = email_address
      this.fullname = fullname
      this.roomname = this.applicant_details.booking_id

      this.$socket.client.emit('add_to_interview', email_address)
      this.$toast(
        {
          component: this.$toastContent,
          props: {
            title: 'Interview notification',
            icon: 'AlertCircleIcon',
            text: 'Get ready! Interview will being soon',
            variant: 'success',
          },
        },
        { timeout: this.$longestTimeout },
      )
      this.$socket.client.emit(
        'start_interview_applicant',
        this.event_details,
        this.applicant_details,
      )
      localStorage.setItem(
        'interview_running',
        JSON.stringify({
          applicant_details: this.applicant_details,
          event_details: this.event_details,
          roomname: this.roomname,
        }),
      )
      this.createMeetUrl()
      this.video_call = true
      // this.setTime(8, 0);
      // this.$refs.countdown.start()
      this.isLoading = true
      const outer_this = this
      setTimeout(() => {
        outer_this.isLoading = false
      }, 2000)
    },
    getApplicants() {
      const { token } = this.$store.state.auth.ActiveUser
      this.$http.defaults.headers.common.Authorization = `Token ${token}`

      const event_id = this.selected_event
      this.$http
        .get(`/api/job-applicants/${event_id}`)
        .then(response => {
          this.all_applicants = response.data.applicants
          this.selected_job = response.data.event_details
          const { success } = response.data

          if (!success) {
            localStorage.removeItem('selected_event')
            this.selected_event = ''
          }
          if (this.selected_job.event_type === 'speed_interview') {
            this.minutes = this.selected_job.interview_duration
          } else {
            this.minutes = ''
          }

          const start_date = new Date(`${this.selected_job.event_details.cleaned_start_time}`)
          const end_date = new Date(`${this.selected_job.event_details.cleaned_end_time}`)
          const current_date = new Date()

          if (
            current_date.getTime() <= end_date.getTime()
            && current_date.getTime() >= start_date.getTime()
          ) {
            this.show_section = true
          } else {
            this.show_section = false
          }

          if (this.all_applicants.length > 0) {
            this.applicant_details = this.all_applicants[0]
          } else {
            this.applicant_details = {}
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Interview room notification',
                  icon: 'AlertCircleIcon',
                  text: 'There are no candidates left to interview.',
                  variant: 'warning',
                },
              },
              { timeout: this.$longestTimeout },
            )
          }

          this.isLoading = false
        })
        .catch(error => {})
    },

    setTime(minutes, seconds) {
      this.time = minutes * 60 + seconds
    },

    selectApplicants() {
      if (this.times_up) {
        return true
      }
      this.$toast(
        {
          component: this.$toastContent,
          props: {
            title: 'Interview notification',
            icon: 'AlertCircleIcon',
            text: 'You cannot proceed until you finish the interview section.',
            variant: 'danger',
          },
        },
        { timeout: this.$longestTimeout },
      )

      return true
    },
    requestVideoCall() {
      if (this.interview_in_progress.includes(this.applicant_details.email_address)) {
        this.$toast(
          {
            component: this.$toastContent,
            props: {
              title: 'Interview notification',
              icon: 'AlertCircleIcon',
              text: `${this.applicant_details.fullname} is currently in another interview room. Please select another applicant or wait until ${this.applicant_details.fullname} is available to initiate the interview process.`,
              variant: 'danger',
            },
          },
          { timeout: this.$longestTimeout },
        )
      } else if (this.online_users.includes(this.applicant_details.email_address)) {
        this.$socket.client.emit(
          'request_video_call',
          this.applicant_details,
          this.selected_job,
        )
        this.$toast(
          {
            component: this.$toastContent,
            props: {
              title: 'Interview notification',
              icon: 'AlertCircleIcon',
              text: `${this.applicant_details.fullname} has been informed to get ready for the interview. Please wait while they join.`,
              variant: 'success',
            },
          },
          { timeout: this.$longestTimeout },
        )
        this.isLoading = true
      } else {
        this.$toast(
          {
            component: this.$toastContent,
            props: {
              title: 'Interview notification',
              icon: 'AlertCircleIcon',
              text: `${this.applicant_details.fullname} is currently offline. Please select another applicant or wait until ${this.applicant_details.fullname} is back online to initiate the interview process.`,
              variant: 'danger',
            },
          },
          { timeout: this.$longestTimeout },
        )
      }
    },
    endInterview() {
      this.times_up = true
      this.video_call = false
      if (this.admin) {
        localStorage.removeItem('interview_running')
        localStorage.removeItem('interviewer_link')
        localStorage.removeItem('meeting_end_link')
        localStorage.removeItem('applicant_link')
        this.$refs.wizard.nextTab()
        this.$socket.client.emit('end_interview', this.applicant_details)

        const api = BigBlueButtonApi.api(this.bbbUrl, this.bbbSecret)
        const { http } = BigBlueButtonApi

        http(this.meetEndUrl).then(response => {})
      } else {
        localStorage.removeItem('interview_running')
        localStorage.removeItem('interviewer_link')
        localStorage.removeItem('meeting_end_link')
        localStorage.removeItem('applicant_link')
        this.event_details = []
        this.$refs.wizard.nextTab()
      }

      // clearInterval(this.timer);
    },

    setInterviewDuration() {},

    onIFrameLoad() {
      this.isLoading = false
    },
  },
}
</script>
<style lang="css">
.card-overlay {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 0;
}
.interview-overlay {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}
</style>
